import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <>
      <section className="term_conditions">
        <div className="container">
          <h1 className="text-center mb-4">Terms & Conditions</h1>
          <section>
            <h2>Introduction</h2>
            <p>
              The terms “you,” “your,” and “yours” refer to the customer. Ocean
              Tech Zone, incorporated under the provisions of the Companies Act,
              1956, hereinafter referred to as “Ocean Tech Zone,” “we,” “us,”
              and “our.” These General Terms and Conditions are governed by our
              Terms of Service. Our Terms of Service take precedence over any
              conflicting General Terms and Conditions provision. BY USING Ocean
              Tech Zone SERVICES AND WEBSITE, YOU CONSENT TO THE TERMS AND
              CONDITIONS OF THESE General Terms and Conditions. IF YOU DO NOT
              AGREE TO THESE General Terms and Conditions, PLEASE DO NOT USE THE
              WEBSITE AND/OR SERVICES.
            </p>
          </section>

          <section>
            <h3>Account Ethics and Confidentiality</h3>
            <p>
              You are entitled to withhold all the account confidentiality
              standards and shall not engage in partial or complete disclosure
              of “Account” information to any third-party resources. You are
              entitled to keep all the information regarding invoices, recent
              transactions, payments, application fields, and other panel
              details confidential.
            </p>
          </section>

          <section>
            <h3>Accuracy and Completeness of Information</h3>
            <p>
              While Ocean Tech Zone strives to ensure that the information
              contained on this Website is accurate and reliable, Ocean Tech
              Zone makes no warranties as to the accuracy, correctness, or
              completeness of any such information and assumes no liability or
              responsibility for any omissions or errors in the content of this
              Website. Ocean Tech Zone reserves the right to revise the
              information contained on this Website at any time in its sole
              discretion without any obligation to notify past, current or
              prospective visitors.
            </p>
          </section>

          <section>
            <h3>Your Use of This Website</h3>
            <p>
              You may download certain content that appears on this Website for
              your personal use, provided you do not remove or modify any
              copyright, trademark, or other proprietary notices. You expressly
              agree that no right, title, or interest in any downloaded
              materials is transferred to you as a result of such downloading or
              copying. There are inherent dangers in downloading materials and
              information from the Internet, and Ocean Tech Zone cautions you to
              make sure that you completely understand the potential risks
              before downloading any such content. You are solely responsible
              for adequate protection and backup of the data and equipment used
              in connection with any information downloaded from this Website,
              and Ocean Tech Zone will not be liable for any damages that you
              may suffer as a result of such download.
            </p>
          </section>

          <section>
            <h3>Changes to the General Terms and Conditions</h3>
            <p>
              We reserve the right to change these General Terms and Conditions
              from time to time without notice by posting the changes to Ocean
              Tech Zone’s Website. When we do, we will also revise the “last
              updated” date of these General Terms and Conditions.
            </p>
          </section>

          <section>
            <h3>Privacy Policy</h3>
            <p>
              Ocean Tech Zone’s use of any personally identifiable information
              you submit to the Website is governed by the Website’s Privacy
              Policy.
            </p>
          </section>

          <section>
            <h3>Registration</h3>
            <p>
              The User has to get registered with us as per the service
              acceptance contract by providing relevant details including your
              contact number, mailing address, office address, and so on. The
              User should provide the current and true information as per the
              requirement of the registration form without fail. If in any
              scenario the information provided by the User stands untrue or
              incomplete, Ocean Tech Zone holds the right to deny, suspend, or
              terminate the services at any instance without giving any prior
              notice. The User is liable to submit identification documents
              necessary as per the regulations of the Department of
              Telecommunications, Government of India. The account shall be set
              for deactivation in case the required documents are not submitted
              within 10 days of account activation, and we shall not be liable
              for any refunds.
            </p>
          </section>

          <section>
            <h3>Services Activation Delay</h3>
            <p>
              We are set to activate the user account within 7 working days of
              payment confirmation with a positive effect. However, the time may
              vary based on a certain set of limitations:
              <ul>
                <li>
                  The setting up of the accounting period may vary based on the
                  changes in customer requirements.
                </li>
                <li>
                  Holidays or Non-working days are not considered as a part of
                  the timeline for the account activation period.
                </li>
              </ul>
            </p>
          </section>

          <section>
            <h3>Exit Policy for Voice Services</h3>
            <p>
              If the customer is not willing to extend/renew the contract upon
              contract expiration, we can transfer the ownership of the number
              upon request to the User as per the following policies:
              <ul>
                <li>Only Virtual mobile numbers can be transferred.</li>
                <li>Landline/Toll-Free/PSTN numbers are not transferable.</li>
                <li>
                  The user is liable to pay a transfer fee of INR 5000 to
                  facilitate the transfer of ownership.
                </li>
                <li>
                  The user has an option to continue with the services until the
                  number porting process is complete by paying the rental and
                  usage charges.
                </li>
                <li>
                  We reserve the right to charge the User up to INR 7500 as a
                  security deposit against the aforementioned charges. Any part
                  of the unused amount will be refunded back to the User after
                  the transfer of ownership is complete.
                </li>
              </ul>
            </p>
          </section>

          <section>
            <h3>Communication</h3>
            <p>
              The User shall give their consent to receive communications from
              Ocean Tech Zone through emails, calls, and SMS for the services
              and transactions on our portal. The users are entitled to register
              their valid e-mail ids and contact numbers for the same. The User
              shall also be entitled to receive promotions, service updates, and
              such other sorts of communications from our end.
            </p>
            <p>
              ContactIn case you have any queries or suggestions about this
              Terms of Use Policy, please reach out to us at
              info@oceantechzone.com
            </p>
            <p>
              TerminationThe User may, as per their best interests, deactivate
              their account at any point in time. Ocean Tech Zone reserves the
              right to suspend or terminate access to our services without
              giving any prior notice or specific reason, and also in case of
              any possible violation of the terms of use. Ocean Tech Zone shall
              not be held liable for any instances of termination of services on
              account of non-renewal or non- payment of dues on time.
            </p>
          </section>

          <section>
            <h3>Plans</h3>
            <p>
              Pre-Paid: The User can renew the services contract anytime post
              expiration of the services within two months from the date of
              contract expiration as per the following guidelines:
            </p>
            <ul>
              <li>
                The User can renew the service contract with the services of the
                previous number and the service plan only if the display number
                is available with us.
              </li>
              <li>
                The User can renew the service contract beyond one month from
                the date of expiration and before two months from the date of
                expiration without a guarantee of a previous service plan.
              </li>
            </ul>
            <p>
              Privacy guidelinesAs per the user registration guidelines, you
              shall be liable to provide true information while creating the
              account and choose a strong password. Ocean Tech Zone reserves the
              right to have full access to customer-specific information and use
              it to an extent as permissible by the regulations of the
              Government of India to provide top-notch services to the users. We
              store your critical information and are liable to keep it secure
              as per the Information Security Act – 2000.
            </p>
          </section>

          <section>
            <h3>Termination</h3>
            <p>
              The User may, as per their best interests, deactivate their
              account at any point in time. Ocean Tech Zone reserves the right
              to suspend or terminate access to our services without giving any
              prior notice or specific reason, and also in case of any possible
              violation of the terms of use. Ocean Tech Zone shall not be held
              liable for any instances of termination of services on account of
              non-renewal or non-payment of dues on time.
            </p>
            <p>
              Use of Content The diverse content available on the Website is to
              be used by the User solely for non-commercial purposes or the
              purpose of the services offered by Ocean Tech Zone. The user is
              not entitled to the ownership of any type of content available on
              the Website. It is strictly not allowed to copy or reproduce the
              content for any purpose except as per the guidelines of this Terms
              of Use. Ocean Tech Zone grants a temporary permit to users to view
              the content available on the Website provided there is no
              violation of copyright and trademark rules mentioned regarding the
              content. The content should not be used for any other purpose
              without prior written permission from the concerned authorities of
              Ocean Tech Zone. We hold the right to modify, alter, renew, or
              change the content of the Website at any point in time without
              providing any prior notice to the User.
            </p>
            <p>
              Fair Usage Policy (FUP) We strive to provide the best-in-class
              services to our customers with relentless dedication. There are
              some scenarios wherein the credits added in the user accounts are
              not utilized for a very long time, or the account is not used for
              a very long time. This non-utilization creates an imbalance for
              the other users as non-operational accounts have to be handled on
              the backend, equally consuming time and causing work constraints.
              As there are very minimal validity impositions on SMS services, we
              intend to build a fair usage scheme to ensure adequate utilization
              of credits within a reasonable time frame (6 months – 1 year).
              Thus, to ensure that the services remain smooth for all our
              customers, we are introducing a Fair Usage Policy with the
              intention of service betterment.
            </p>
          </section>

          <section>
            <h3>FUPs for SMS and Voice</h3>
            <p>
              The credits in the user account shall no longer remain valid,
              followed by account deactivation and approved sender id and
              template de-listings if the criteria fall in any of the following
              scenarios:
            </p>
            <p>
              <b>a.</b> If there is no account usage for six consecutive months
              and the User has not logged into his account for the last three
              months.
            </p>
            <p>
              <b>b.</b> If there has been account usage in the last six months,
              but the User has not logged into the account for the past 3
              months, the account will be kept on hold for deactivation as per
              the policy (ref. a).
            </p>
            <p>
              <b>c.</b> If there has been no usage of the accounts in the past
              six months, but the User has logged into his account in the last
              three months, then the account will be kept on hold for
              deactivation as per the policy (ref. a).
            </p>
            <p>
              <b>d.</b> Under no circumstances shall there be a refund for
              credits used against respective telecom regulations. The User is
              expected to maintain regulations compliant usage of SMS credits
              throughout the account tenure.
            </p>

            <p>
              <b>e.</b> The User is expected to have a minimum of 10% usage of
              credits within the first month from the date of credit addition to
              the user account.
            </p>
            <p>
              <b>f.</b> The User is expected to have a minimum of 50 % usage of
              credits within the first two months from the date of credit
              addition to the user account.
            </p>
            <p>
              <b>g.</b> The effective credit balance on the voice platform shall
              be forfeited in case the account validity of the User has lapsed.
              The users are expected to utilize their balance within the
              validity period or to get their validity extended for the same.
            </p>
          </section>
          <section>
            <h3>Scope</h3>
            <p>
              The User is entitled to use the services in context with cloud
              communications for business purposes, providing Inbound and
              outbound solutions in context with IT support solutions.
            </p>
          </section>
          <section>
            <h3>Payment</h3>
            <p>
              The User shall be on agreeable terms with any forms of electronic
              payment modes available with Ocean Tech Zone for service payments
              against the services offered. We therefore, in full terms, are
              authorized to process any type of payment electronically by the
              User against the services. The invoice for the charged services
              shall be generated and shared with the User at fixed periodic
              intervals. The User is responsible and required to cross-check and
              verify the invoice. If any discrepancies are to be found, the same
              shall be notified to Ocean Tech Zone within 7 days of the date of
              invoice generation. Invoice reminders shall be sent to the User at
              regular intervals.
            </p>
            <p>
              Regulatory Guidelines Ocean Tech Zone works under regulations
              formulated by the Department of Telecommunications, Government of
              India (DOT), and Telecom Regulatory Authority of India (TRAI).
              Ocean Tech Zone reserves the right for modification, alteration,
              or removal of specific features or offerings based on the law and
              regulations without any prior notice. Ocean Tech Zone holds no
              abiding policy to fulfil any type of service request demanded by
              the User. The provision of service request fulfilment shall be at
              the sole discretion of Ocean Tech Zone depending on priority
              parameters.
            </p>
          </section>
          <section>
            <h3>Support</h3>
            <ul>
              <li>
                Ocean Tech Zone extends service support from 8.00 am to 8.00 pm
                over email at info@oceantechzone.com
              </li>
              <li>
                The general response time for any query or issue reported is 24
                hours which may extend depending on the severity of the issue
                reported.
              </li>
              <li>
                Ocean Tech Zone strives to resolve any type of issue reported in
                the minimum time possible with regards to the service provided
                but holds no guarantee that the issue will be resolved.
              </li>
            </ul>
          </section>
          <section>
            <h3>Governing Law and Jurisdiction</h3>
            <p>
              The services agreement of Ocean Tech Zone falls strictly under the
              Laws of the Republic of India, and any disputes, disagreements
              (including questionable parameters regarding services) shall be
              taken to the relevant court, i.e. courts in New Delhi. This
              agreement stands firm, in compliance with the Telecom Resources
              Undertaking, the DND Undertaking, and the privacy policy in
              correspondence with the terms of usage of services and is above
              any other agreement or understanding between a user and Ocean Tech
              Zone. If you have any concerns or queries with the agreement,
              please feel free to contact us at info@oceantechzone.com or call
              us
            </p>
          </section>
          <section>
            <h3>Disclaimers</h3>
            <p>
              THE INFORMATION ON THIS SITE IS PROVIDED “AS IS” WITHOUT WARRANTY
              OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A
            </p>
            <p>
              PARTICULAR PURPOSE AND NON-INFRINGEMENT. No advice or information,
              whether oral or written, obtained by a user from Ocean Tech Zone,
              shall create any warranty not expressly made herein. If you
              download or copy any material from this Website, you do so at your
              sole discretion and risk, and consequently, you will be
              responsible for any damage to your computer system or loss of data
              that results from the download or copying of any material or
              software. Some of Ocean Tech Zone’s Services require the use of
              third-party services, products, or networks. Ocean Tech Zone will
              make commercially reasonable efforts to communicate any policies,
              requirements, or guidelines of those third parties to you. You
              agree to follow those policies, requirements, or guidelines. ANY
              ACTUAL OR ALLEGED VIOLATION OF A THIRD-PARTY POLICY, REQUIREMENT,
              OR GUIDELINE BY YOU IS YOUR RESPONSIBILITY. Ocean Tech Zone MAKES
              NO WARRANTY OR REPRESENTATION THAT ITS WEBSITE OR SERVICES WILL BE
              UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE IF ANY VIOLATION OF A
              THIRD-PARTY POLICY, REQUIREMENT, OR GUIDELINE BY YOU IS ALLEGED BY
              A THIRD PARTY.
            </p>
          </section>
          <section>
            <h3>Products and Services</h3>
            <p>
              All products (including software) and services of Ocean Tech Zone
              may only be accessed and used according to a separate product or
              service agreement. If you have accessed such products or services
              before entering into a separate product or service agreement, such
              access violates Ocean Tech Zone’s General Terms and Conditions.
              You shall immediately cease using such services or delete any such
              products from your computer or server until you become an
              authorized user of such products or services.
            </p>
          </section>
          <section>
            <h3>Limitation of Liability</h3>
            <p>
              IN NO EVENT WILL Ocean Tech Zone BE LIABLE FOR ANY DIRECT,
              INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES
              HOWEVER THEY MAY ARISE, WHETHER IN AN ACTION OF CONTRACT,
              NEGLIGENCE OR TERMS OTHER TORTIOUS ACTION, ARISING OUT OF OR IN
              CONNECTION WITH THE:
            </p>
            <p>
              <b>(i)</b> USE OF THE INFORMATION CONTAINED ON THIS WEBSITE; (ii)
              USE OF SOFTWARE DOWNLOADED OR LINKED TO FROM THIS WEBSITE; OR
              (iii) FOR THE FAILURE TO PROVIDE SERVICES OR INFORMATION AVAILABLE
              FROM THIS WEBSITE, EVEN IF Ocean Tech Zone HAS BEEN PREVIOUSLY
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT YOU HAVE
              ANY DISPUTE WITH ONE OR MORE THIRD PARTIES AS A RESULT OF YOUR USE
              OF THE WEBSITE, CONTENT OR SERVICES, OR ARE IN ANY WAY DAMAGED AS
              A RESULT OF ANY THIRD PARTY IN CONNECTION THEREWITH, YOU HEREBY
              RELEASE AND COVENANT NOT TO SUE OR OTHERWISE MAKE A CLAIM, DEMAND
              OR FILE ANY LEGAL ACTION OR INSTITUTE ANY LEGAL OR REGULATORY
              PROCEEDINGS AGAINST Ocean Tech Zone, ITS AFFILIATES, OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, AND SUPPLIERS FROM,
              FOR ANY CLAIMS, ACTIONS, DEMANDS OR DAMAGES (WHETHER DIRECT,
              INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL), OF WHATEVER KIND
              OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, WHETHER
              FORESEEABLE OR NOT, DISCLOSED OR UNDISCLOSED.
            </p>
          </section>
          <section>
            <h3>Indemnification</h3>
            <p>
              You agree to defend, indemnify and hold Ocean Tech Zone harmless
              from and against any third-party claims, damages, costs, and
              expenses, including reasonable attorney’s fees, arising from or
              related to your use of this Website.
            </p>
          </section>
          <section>
            <h3>Links to Third-Party Websites</h3>
            <p>
              Any links to third party sites are provided as a convenience to
              you, and such sites are neither owned nor operated by Ocean Tech
              Zone. Ocean Tech Zone has no control over these linked sites, is
              not responsible for the contents of these sites, makes no
              representations or warranties concerning these linked sites, and
              shall not be liable for any damages or injury arising from the
              content of these linked sites. Your viewing and use of any
              third-party sites are at your sole discretion and risk.
            </p>
          </section>
          <section>
            <h3>Copyright Notice</h3>
            <p>
              Unless otherwise noted, the graphic images, buttons, and text
              contained in this Website are the exclusive property of Ocean Tech
              Zone and are Copyright 2014-2020 of Ocean Tech Zone. All rights
              reserved. Except for your personal use as permitted herein, these
              items may not be copied, displayed, transmitted, or reproduced in
              any form without the express written permission of Ocean Tech
              Zone.
            </p>
          </section>
          <section>
            <h3>Submissions, Suggestions, and Community Participation</h3>
            <p>
              In the event you elect to communicate suggestions for improvements
              to the Website or any of Ocean Tech Zone’s services or properties
              (collectively, “Feedback”), we shall own all right, title, and
              interest in and to the same, even if you have designated the
              Feedback as confidential, and we shall be entitled to use the
              Feedback without restriction. Furthermore, any other content or
              information you post or provide to Ocean Tech Zone via comments,
              forums, e-mails, and the like (collectively, “Communications”)
              shall be considered the property of Ocean Tech Zone. You hereby
              irrevocably assign all right, title, and interest in and to the
              Feedback and Communications to us and agree to provide us such
              assistance as we may require to document, perfect, and maintain
              our rights to the Feedback and Communications.
            </p>
          </section>
          <section>
            <h3>Trademark Notice</h3>
            <ul>
              <li>
                The Ocean Tech Zone logo is a trademark of Ocean Tech Zone.
              </li>
              <li>
                Other product and service names mentioned in these documents may
                be trademarks and/or service marks of others.
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
