import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <div className="privacy-policy-container">
      <div className="container">
        <div className="top-section">
          <h1 className="text-center mb-4">Privacy Policy</h1>
          <p className="text-center">Updated as on 04-Jan-2024</p>
          <p>
            We care about protecting the personal information of our customers
            and visitors who use our website <a
              href="https://www.oceantechzone.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.oceantechzone.com
            </a>
            , our products, or services (collectively, our "Users"). This
            Privacy Policy governs the manner in which Ocean Tech Zone collects,
            uses, shares, maintains, and discloses information collected from
            users (each, a "User", you) of the
            <a
              href="https://www.oceantechzone.com.io/privacy_policy.php"
              target="_blank"
              rel="noopener noreferrer"
            > Site
            </a>
            . This privacy policy applies to the Site and all products and
            services offered by Ocean Tech Zone. In this policy, "we", "us" and
            "our" refer to Ocean Tech Zone and the Ocean Tech Zone Corporate
            family.
          </p>
          <p>
            For the purposes of applicability of the European Union's General
            Data Protection Regulation 2016/679, or GDPR, on Ocean Tech Zone and
            each of the products or services for which you have signed up, it
            applies only if you are located in a jurisdiction where these
            regulations are implemented.
          </p>

          <h3>Information Covered by This Privacy Policy</h3>
          <p>
            This privacy policy covers personal information, including any
            information we collect, use, and share from you, as described
            further below. This privacy policy applies to all websites in the
            Ocean Tech Zone corporate family, our products and services, and our
            mobile applications (collectively, the "Services"). This privacy
            policy does not cover how our Users may use or share data that they
            collect using our services.
          </p>
          <p>
            When you use any of our products or services, your personal
            information will be collected, used, and shared consistent with the
            provisions of this privacy policy as well as the following
            agreements/contracts related to particular products and services
            offered by Ocean Tech Zone.
          </p>
        </div>

        <div className="section1">
          <h3>1. Information We Collect From You</h3>
          <h3 className="mt-4">Personal Identification Information</h3>
          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, and in connection with other activities,
            services, features, or resources we make available on our Site.
            Users may be asked for, as appropriate, name, email address, mailing
            address, phone number, and credit card information. Users may,
            however, visit our Site anonymously. We will collect personal
            identification information from Users only if they voluntarily
            submit such information to us. Users can always refuse to supply
            personal identification information, except that it may prevent them
            from engaging in certain Site-related activities.
          </p>

          <h3 className="mt-4">Non-Personal Identification Information</h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer, and
            technical information about Users' means of connection to our Site,
            such as the operating system and the Internet service provider’s
            utilized and other similar information.
          </p>

          <h3 className="mt-4">Web Browser Cookies</h3>
          <p>
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies or to alert them
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>

          <h3 className="mt-4">Ocean Tech Zone’s Business Products</h3>
          <p>
            We create apps, services, features, APIs, software, or websites that
            enable businesses to interact with users of our products and
            services ("Business Products"). Partners (the "data controllers")
            may submit personal information about their customers to us using
            our Business Products. While Partners decide what information to
            submit, it typically includes things like customer phone numbers and
            other information under the Partner's control. For more information,
            customers may contact the relevant Partner. We process these
            personal data provided by Partners to provide Business Products in
            accordance with the terms applicable to the relevant Business
            Product and otherwise with the Partners' instructions.
          </p>
        </div>

        <div className="section2">
          <h3>2. HOW WE USE COLLECTED INFORMATION</h3>
          <p>
            Ocean Tech Zone may collect and use Users information for the
            following purposes we may process data about your use of our website
            and services ("usage data"). The usage data may include but not
            limited to your IP address, geographical location, browser type and
            version, operating system, referral source, length of visit, page
            views and website navigation paths, as well as information about the
            timing, frequency and pattern of your service use. The source of the
            usage data is our analytics tracking system. This usage data may be
            processed for the purposes of analyzing the use of the website and
            services. The legal basis for this processing is consent and our
            legitimate interests, namely monitoring and improving our website
            and services.
          </p>
          <p>
            We may process your account data ("account data"). The account data
            may include your name and email address. The account data may be
            processed for the purposes of operating our website, providing our
            services, ensuring the security of our website and services,
            maintaining back-ups of our databases and communicating with you.
            The legal basis for this processing is consent and our legitimate
            interests, namely the proper administration of our website and
            business.
          </p>
          <p>
            We may process your account data ("account data"). The account data
            may include your name and email address. The account data may be
            processed for the purposes of operating our website, providing our
            services, ensuring the security of our website and services,
            maintaining back-ups of our databases and communicating with you.
            The legal basis for this processing is consent and our legitimate
            interests, namely the proper administration of our website and
            business.
          </p>

          <p>
            We may process your personal data that are provided in the course of
            the use of our services ("service data"). The service data may
            include logs, usage reports, services used, date and time stamps of
            the service usage, telephone numbers. The source of the service data
            is the usage of our service and website. The service data may be
            processed for the purposes of operating our website, providing our
            services, ensuring the security of our website and services,
            maintaining back-ups of our databases, improving our services,
            developing new services, measuring performance and communicating
            with you. The legal basis for this processing is your consent.
          </p>
          <p>
            We may process information contained in any enquiry you submit to us
            regarding our products and services ("enquiry data"). The enquiry
            data may be processed for the purposes of offering, marketing and
            selling relevant products and/or services to you. The legal basis
            for this processing is your consent.
          </p>
          <p>
            We may process information contained in or relating to any
            communication that you send to us ("correspondence data"). The
            correspondence data may include the communication content and
            metadata associated with the communication. Our website will
            generate the metadata associated with communications made using the
            website contact forms. The correspondence data may be processed for
            the purposes of communicating with you and record-keeping. The legal
            basis for this processing is our legitimate interests, namely the
            proper administration of our website and business and communications
            with you.
          </p>
          <p>
            We may process information that you provide to us for the purpose of
            subscribing to our email notifications and/or newsletters
            ("notification data"). The notification data may be processed for
            the purposes of sending you the relevant notifications and/or
            newsletters. The legal basis for this processing is consent.
          </p>
          <p>
            We may process any of your personal data identified in this policy
            where necessary for the establishment, exercise or defense of legal
            claims, whether in court proceedings or in an administrative or
            out-of-court procedure. The legal basis for this processing is our
            legitimate interests, namely the protection and assertion of our
            legal rights, your legal rights and the legal rights of others.
          </p>
          <p>
            We may process any of your personal data identified in this policy
            where necessary for the purposes of obtaining or maintaining
            insurance coverage, managing risks, or obtaining professional
            advice. The legal basis for this processing is our legitimate
            interests, namely the proper protection of our business, products
            and services against risks.
          </p>
          <p>
            In addition to the specific purposes for which we may process your
            personal data set out in this Section, we may also process any of
            your personal data where such processing is necessary for compliance
            with a legal obligation to which we are subject, and/or in order to
            protect your vital interests or the vital interests of another
            natural person. In addition to the above, we may process the data
            collected for the protection of our users and public at large. We
            use information to help improve the safety and reliability of our
            services. This includes detecting, preventing, and responding to
            fraud, abuse, security risks, and technical issues that could harm
            Ocean Tech Zone, our users, or the public. In addition to the above,
            we may process the data collected for the purpose of analytics and
            measurement to understand how our services are used and for ensuring
            that our services are working as intended, such as tracking outages
            or troubleshooting issues that you report to us. And we also use
            your information to make improvements to our services.
          </p>
        </div>
        <div className="section3">
          <h3>3. SHARING YOUR PERSONAL INFORMATION</h3>
          <p>
            As described below, we will only share certain personal information:
          </p>
          <h3 className="mt-4">Authorized Users</h3>
          <p>
            All users authorized by you to have access to your account can view
            personal information stored in the account. A primary account holder
            can view personal information saved in subaccounts to which they
            have authorized access. We share information about authorized users
            only for legitimate purposes consistent with this privacy policy,
            including servicing your account and marketing products and services
            to you.
          </p>

          <h3>SHARING WITHIN THE Ocean Tech Zone CORPORATE FAMILY</h3>
          <p>
            We share personal information with other members of the Ocean Tech
            Zone corporate family to allow our corporate affiliates to contact
            you with offers, services or products that may be of interest to you
            and to provide you with their products and services. Any such
            corporate affiliate may use your information only according to the
            terms of this privacy policy. If you are located in a jurisdiction
            where such sharing requires your permission, we will only do so with
            your consent.
          </p>
          <h3>SHARING WITH INSURERS AND PROFESSIONAL ADVISERS</h3>
          <p>
            We may disclose your data to our insurers and/or professional
            advisers insofar as reasonably necessary for the purposes of
            obtaining or maintaining insurance coverage, managing risks,
            obtaining professional advice, or the establishment, exercise or
            defence of legal claims, whether in court proceedings or in an
            administrative or out-of-court procedure.
          </p>
          <h3>SHARING WITH PARTNERS</h3>
          <p>
            When we share personal information with certain third-party
            partners, including marketing and advertising partners, that
            information includes your name, email address and other information
            enabling partners to: (a) assist you in using our products and
            services, (b) contact you with offers, services or products that may
            be of interest to you, and (c) provide you with their products or
            services. If you are located in a jurisdiction where such sharing
            requires your consent, we will only do so with your consent. Please
            note that if you access our services through any tool that hides
            your location, such as through a virtual private network, you may
            not receive our request for permission because we were not able to
            identify you as being located in a jurisdiction where your
            permission is required. Further, our partners are prohibited from
            using your contact information for any purpose beyond those set
            forth above without your consent. We will not provide our partners
            with your credit card information. In the event we collect
            information from you in connection with an offer that is jointly
            presented by us and a partner, we will let you know who is
            collecting the information and whose privacy policy applies, as well
            as any options you may have regarding use of your information.
          </p>
          <h3>SHARING WITH THIRD PARTY SERVICE PROVIDERS AND VENDORS</h3>
          <p>
            Occasionally, we enter into contracts with carefully selected third
            parties so that they can assist us in servicing you (for example,
            providing you with customer service, fraud detection and deterrence
            or access to advertising assets and providing us with information
            technology and storage services) or to assist us in our own
            marketing and advertising activities (including providing us with
            analytic information and search engine optimization services). Our
            contracts with such third parties prohibit them from using any of
            your personal information for any purpose beyond the purpose for
            which it was shared. If you purchase a product or service from a
            third party through one of our product or brands, we will pass your
            personal information to such third party in order for them to
            fulfill your order and provide you the relevant services. We also
            share non-personal information with certain third parties, including
            the media, industry observers, marketing and advertising partners,
            vendors, customers, potential customers or partners. For example, we
            disclose mobile search trends, email open rates by industry,
            campaign best practices or the number of users that have been
            exposed to, or clicked on, our websites or evaluated or purchased
            our products and services. We may use third party service providers
            to help us operate our business and the Site or administer
            activities on our behalf, such as sending out newsletters or surveys
            or serve us services as a telecom operator. We may share your
            information with these third parties for those limited purposes only
            and with your necessary consent under the law, if any.
          </p>

          <h3>Payment Services Providers</h3>
          <p>
            Financial transactions relating to our website and services may be
            handled by our payment services providers. We will share transaction
            data with our payment services providers only to the extent
            necessary for the purposes of processing your payments, refunding
            such payments and dealing with complaints and queries relating to
            such payments and refunds. You can find information about the
            payment services providers' privacy policies and practices at
            [URLs].
          </p>
          <h3>Compliance with Laws and Law Enforcement Requests</h3>
          <p>
            We may disclose your Information (including your Personal
            Information) to a third party if (a) we believe that disclosure is
            reasonably necessary to comply with any applicable law, regulation,
            legal process or governmental request, (b) to enforce our
            agreements, policies and terms of service, (c) to protect the
            security or integrity of Ocean Tech Zone's products and services,
            (d) to protect Ocean Tech Zone's , our customers or the public from
            harm or illegal activities, or (e) to respond to an emergency which
            we believe in the good faith requires us to disclose information to
            assist in preventing the death or serious bodily injury of any
            person. However, nothing in this Privacy Policy is intended to limit
            any legal defenses or objections that you may have to a third
            party's, including a government's, request to disclose your
            information. Ocean Tech Zone also reserves the right to disclose
            personally identifiable information and/or
            non-personally-identifiable information that Ocean Tech Zone
            believes, in good faith, is appropriate or necessary to enforce our
            Terms of Service, take precautions against liability, to investigate
            and defend itself against any third-party claims or allegations, to
            assist government enforcement agencies, to protect the security or
            integrity of our web site, and to protect the rights, property, or
            personal safety of Ocean Tech Zone, our Users or other natural
            person.
          </p>
          <h3>BUSINESS TRANSFERS</h3>
          <p>
            we may share or transfer your information (including your Personal
            Information) in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company. We will notify all
            users under the contract of our services by email and/or a prominent
            notice on our website of any such transfer.
          </p>
        </div>
        <div className="section4">
          <h3>4. SECURITY</h3>
          <p>
            The transmission of information via the internet, email or text
            message is not completely secure. Although we will do our best to
            protect your personal information, we cannot guarantee the security
            of your information transmitted through the Services or over email;
            any transmission is at your own risk. Once we have received your
            information, we will take appropriate technical and organizational
            measures to safeguard your personal information against loss, theft
            and unauthorized use, access or modification. When we collect
            financial account information, such as credit card numbers, we
            protect its transmission through the use of encryption such as the
            Transport Layer Security (TLS) protocol.
          </p>
        </div>
        <div className="section5">
          <h3>5. How we protect your information</h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site. Sensitive and private data exchange between the
            Site and its Users happens over a SSL secured communication channel
            and is encrypted and protected with digital signatures.
          </p>
        </div>
        <div className="section6">
          <h3>6. International transfers of your personal data</h3>
          <p>
            In order for us to provide the Services to you, your personal
            information will be transferred to, and stored at/processed in the
            United States, Europe and Asia. Your personal data is also processed
            by staff operating outside the European Economic Area (EEA), such as
            in Asia and the USA, who work for us or for one of our suppliers. We
            will take all steps reasonably necessary to ensure that your
            personal data is treated securely and in accordance with this
            policy. For transfers of personal information within the Ocean Tech
            Zone corporate family, such transfer will be under the Commission's
            model contracts for the transfer of personal data to third countries
            (i.e., the standard contractual clauses), pursuant to Decision
            2004/915/EC and 2010/87/EU. For transfers of data to third parties,
            such transfers will either (i) be under the Commission's model
            contracts for the transfer of personal data to third countries
            (i.e., the standard contractual clauses), pursuant to Decision
            2004/915/EC and 2010/87/EU; or (ii) rely on the EU-US Privacy Shield
            Framework, to the extent applicable. You can contact the Data
            Protection Officer listed below to obtain a copy of the data
            transfer agreement or more information regarding the relevant
            safeguard we put in place. For more information about the EU-US
            Privacy Shield Framework, please visit the U.S. Department of
            Commerce's Privacy Shield website. We and our group companies have
            offices and facilities in India and USA. The hosting facilities for
            our website are situated in United States, Europe and Asia. The
            European Commission has made an "adequacy decision" with respect to
            the data protection laws of each of these countries. Transfers to
            each of these countries will be protected by appropriate safeguards,
            namely the use of standard data protection clauses adopted or
            approved by the European Commission, a copy of which can be obtained
            from
            https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en
            You acknowledge that the personal data that you submit for
            publication through our website or services may be available, via
            the internet, apps, website around the world. We cannot prevent the
            use or misuse of such personal data by others.
          </p>
        </div>
        <div className="section7">
          <h3>7. Third party websites</h3>
          <p>
            Users may find advertising or other content on our Site that link to
            the sites and services of our partners, suppliers, advertisers,
            sponsors, licensors and other third parties. We do not control the
            content or links that appear on these sites and are not responsible
            for the practices employed by websites linked to or from our Site.
            In addition, these sites or services, including their content and
            links, may be constantly changing. These sites and services may have
            their own privacy policies and customer service policies. Browsing
            and interaction on any other website, including websites which have
            a link to our Site, is subject to that website's own terms and
            policies.
          </p>
        </div>
        <div className="section8">
          <h3>8. UNAUTHORIZED ACCOUNTS</h3>
          <p>
            If an account or profile was created without your knowledge or
            authorization, please contact customer support at the brand on which
            the account or profile is located to request removal of the account
            or profile.{" "}
          </p>
        </div>
        <div className="section9">
          <h3>9. RETENTION OF PERSONAL INFORMATION</h3>
          <p>
            We retain your personal information to provide services to you and
            as otherwise necessary to comply with our legal obligations, resolve
            disputes, and enforce our agreements. Notwithstanding the other
            provisions of this Section, we may retain your personal data where
            such retention is necessary for compliance with a legal obligation
            to which we are subject, or in order to protect your vital interests
            or the vital interests of another natural person.
          </p>
        </div>
        <div className="section10">
          <h3>10. Our policy towards children</h3>
          <p>
            Ocean Tech Zone Services are not directed to individuals under 18.
            We do not knowingly collect Personal Information from children under
            18. If we become aware that a child under 18 has provided us with
            Personal Information, we will take steps to delete such information.
            If you become aware that a child has provided us with Personal
            Information, please contact our Support Services at
            info@oceantechzone.com
          </p>
        </div>
        <section className="section11">
          <h3>11. Your rights</h3>

          <h3>11.1</h3>
          <p>
            In this Section, we have summarized the rights that you have under
            data protection law. Some of the rights are complex, and not all of
            the details have been included in our summaries. Accordingly, you
            should read the relevant laws and guidance from the regulatory
            authorities for a full explanation of these rights.
          </p>

          <h3>11.2</h3>
          <p>
            Your principal rights under data protection law are: the right to
            access; the right to rectification; the right to erasure; the right
            to restrict processing; the right to object to processing; the right
            to data portability; the right to complain to a supervisory
            authority; and the right to withdraw consent.
          </p>

          <h3>11.3</h3>
          <p>
            To the extent that the legal basis for our processing of your
            personal data is: Your consent; or that the processing is necessary
            for the performance of a contract to which you are party with us or
            with our partners to whom we are providing services as a processor
            or in order to take steps at your request prior to entering into a
            contract, and such processing is carried out by automated means, you
            have the right to receive your personal data from us in a
            structured, commonly used and machine-readable format. However, this
            right does not apply where it would adversely affect the rights and
            freedoms of others.
          </p>

          <h3>11.4</h3>
          <p>
            If you consider that our processing of your personal information
            infringes data protection laws, you have a legal right to lodge a
            complaint with a supervisory authority responsible for data
            protection. You may do so in the EU member state of your habitual
            residence, your place of work or the place of the alleged
            infringement.
          </p>

          <h3>11.5</h3>
          <p>
            To the extent that the legal basis for our processing of your
            personal information is consent, you have the right to withdraw that
            consent at any time. Withdrawal will not affect the lawfulness of
            processing before the withdrawal.
          </p>

          <h3>11.6</h3>
          <p>
            You may exercise any of your rights in relation to your personal
            data by written notice to us at info@oceantechzone.com
          </p>
          <section className="section12">
            <h3>12. Advertisements</h3>
            <p>
              Ads appearing on our site may be delivered to Users by advertising
              partners, who may set cookies. These cookies allow the ad server
              to recognize your computer each time they send you an online
              advertisement to compile non-personal identification information
              about you or others who use your computer. This information allows
              ad networks to, among other things, deliver targeted
              advertisements that they believe will be of most interest to you.
              This privacy policy does not cover the use of cookies by any
              advertisers.
            </p>
          </section>

          <section className="section13">
            <h3>13. Changes to this privacy policy</h3>

            <h3>13.1</h3>
            <p>
              We may update this policy at its discretion from time to time by
              publishing a new version on our website.
            </p>

            <h3>13.2</h3>
            <p>
              You should check this page occasionally to ensure you are happy
              with any changes to this policy. You acknowledge and agree that it
              is your responsibility to review this privacy policy periodically
              and become aware of modifications.
            </p>

            <h3>13.3</h3>
            <p>
              We may notify you of significant changes to this policy by email
              or through the private messaging system on our website.
            </p>
          </section>

          <section className="section14">
            <h3>14. Your acceptance of these terms</h3>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </section>

          <section className="section15">
            <h3>15. Compliance with privacy laws and regulations</h3>
            <p>
              You as a User or partner agree not to use the Ocean Tech Zone
              Services in a manner that would violate laws protecting an
              individual's privacy rights, health, safety or financial data,
              including but not to the Health Insurance Portability and
              Accountability Act of 1996 as amended (HIPAA), the
              Gramm-Leach-Bliley Act and its implementing regulations, European
              Union's General Data Protection Regulation 2016/679, or GDPR, the
              Privacy Rule and the Safeguards Rule thereof.
            </p>
          </section>

          <section className="section16">
            <h3>16. Complaints or inquiry</h3>
            <p>
              If you have any questions or complaints about this Privacy Policy,
              the practices of this site, or your dealings with this site,
              please contact us at: info@oceantechzone.com
            </p>
          </section>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
